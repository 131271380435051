import React from "react"
import styled from "styled-components"

import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

const Wrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;
`

const Title = styled.div`
  color: #6fc2b4;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
  }
`

const Subtitle = styled.div`
  color: #003d6a;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 25px;
    font-weight: 300;
    line-height: 30px;
  }
`
const StyledIamge = styled.img`
  object-fit: contain;
  max-width: initial;
  margin-top: 30px;
  width: 100%;
`

const AboutTimeline = ({ title, subtitle, image }) => {
  return (
    <Container id="timelineWrapper" className="timeline" overflowDisabled>
      <Wrapper>
        <Row>
          <Col xs={12} md={12} xl={12}>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            <StyledIamge src={image.sourceUrl} alt={image.altText} />
          </Col>
        </Row>
      </Wrapper>
    </Container>
  )
}

export default AboutTimeline
