import { gsap } from "@gsap/shockingly/dist/gsap"
import { ScrollTrigger } from "@gsap/shockingly/ScrollTrigger"
import React, { useEffect, useState } from "react"
import Slider from "react-slick"
import styled from "styled-components"

import Hidden from "~components/Hidden"
import useWindowDimensions from "~hooks/useWindowDimensions"
import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

import AnimatedButton from "../../components/AnimatedButton"

const StyledContainer = styled(Container)`
  @media screen and (min-height: 900px) and (max-height: 1050px) {
    margin-top: 150px;
  }
`

const Wrapper = styled.div`
  margin-top: 32px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin-bottom: 32px;
  }
  width: 100%;
`

const MB65 = styled.div`
  padding-bottom: 35px;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-bottom: 65px;
  }
`

const Roles = styled.div`
  margin-bottom: 20px;

  @media ${({ theme }) => theme.breakpoint.lg} {
    margin-bottom: 30px;
  }
`

const People = styled.div`
  .box {
    position: relative;
    box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.11);
    padding: 0;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 22px;
  }
  @media ${({ theme }) => theme.breakpointDown.md} {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
`

const StyledRow = styled.div``

const Title = styled.div`
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
  color: #6fc2b4;
  font-family: Palanquin;
  margin-top: 30px;
  margin-bottom: 10px;

  @media ${({ theme }) => theme.breakpoint.md} {
    margin-bottom: 45px;
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
  }
`

const PersonName = styled.div`
  font-weight: 600;
  font-size: 22px;
  line-height: 25px;
  color: #003d6a;
  font-family: Palanquin;
  margin-bottom: 8px;
`

const PersonJobTitle = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 20px;
  color: #003d6a;
  margin-bottom: 16px;
  font-family: Palanquin;
`

const PersonContent = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #003d6a;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`

const RoleTab = styled.div`
  font-weight: 400;
  font-size: 18px;
  /* color: #003d6a; */
  color: ${props => (props.active ? "#C0D104" : "#003d6a")};
  opacity: ${props => (props.active ? "100%" : "60%")};
  box-sizing: border-box;
  flex-shrink: 0;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 33px;
  box-shadow: ${props => (props.active ? "0px 0px 0px 2px #C0D104" : "0px")};
  text-align: center;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  padding-left: 17px;
  padding-right: 17px;
  cursor: pointer;
  position: relative;
  padding-bottom: 3px;
  @media ${({ theme }) => theme.breakpointDown.md} {
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
  }
`

const PersonImg = styled.img`
  object-fit: cover;
  object-position: center top;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  display: block;
  height: 270px;
  @media ${({ theme }) => theme.breakpoint.md} {
    height: 350px;
  }
  width: 100%;
  padding: 0;
`

const InfoType = styled.div`
  width: 67px;
  color: #003d6a;
  font-family: Palanquin;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
`

const InfoContent = styled.div`
  color: #003d6a;
  font-family: Palanquin;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
`

const PersonInfo = styled.div`
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 65px;
`

const StyledButton = styled(AnimatedButton)`
  padding: 0;
  position: absolute;
  bottom: 0;
  @media ${({ theme }) => theme.breakpointDown.md} {
    width: 100%;
    .circle {
      max-width: 100% !important;
    }
    .button-text {
      color: white !important;
      margin-bottom: 0 !important;
    }
    .icon.arrow {
      padding-bottom: 2px !important;
    }
  }
`

const StyledRolesRow = styled.div`
  overflow-x: scroll;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 65px;
  /* display: block; */
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 5px;
  div {
    white-space: nowrap;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    overflow: initial;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;
    div + div {
      margin-left: 20px;
      margin-top: 16px;
    }
    & > div {
      margin: 0 auto;
      width: 100%;
      text-align: center;
    }

    margin: 0;
    width: initial;
    div + div {
      margin-top: 0px;
    }
    & > div {
      margin: 0;
      width: initial;
      text-align: left;
    }
  }
`

const settings = {
  // centerMode: true,
  dots: false,
  arrows: false,
  appendArrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 2000,
  autoplay: false,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 9999,
      settings: "unslick",
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true,
        centerPadding: "20px",
        // dots: true,
      },
    },
  ],
}
const StyledWrapper = styled.div``

const StyledSlider = styled(Slider)`
  @media ${({ theme }) => theme.breakpointDown.md} {
    all: unset;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    .slick-list {
      padding-top: 30px;
      padding-bottom: 30px;
      /* overflow: visible; */
    }
    .slick-slide {
      & > div {
        height: 100%;
        ${StyledWrapper} {
          height: 100%;
        }
      }
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;
    }
    .slick-track {
      display: flex !important;
      padding-top: 20px;
      padding-bottom: 36px;
    }

    .slick-slide {
      height: inherit !important;
    }
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @media ${({ theme }) => theme.breakpoint.md} {
      grid-template-columns: repeat(2, minmax(300px, 1fr));
      gap: 30px;
    }

    @media ${({ theme }) => theme.breakpoint.lg} {
      grid-template-columns: repeat(3, minmax(200px, 1fr));
      gap: 30px;
    }
  }
`
const AboutTeam = ({ title, roles }) => {
  const { width } = useWindowDimensions()

  const [activeRoleIndex, setActiveRoleIndex] = useState(0)

  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    if (width > 960) {
      ScrollTrigger.refresh()
    }
  }, [])

  useEffect(() => {
    if (width > 960) {
      // tl?.scrollTrigger?.refresh()
      ScrollTrigger.refresh()
      // console.log(ScrollTrigger.getAll()[0])
    }
  }, [activeRoleIndex])

  return (
    <StyledContainer id="team" overflowDisabled={true}>
      <Wrapper>
        <MB65>
          <Title>{title}</Title>
          <Roles>
            <StyledRolesRow>
              {roles &&
                roles.map((item, index) => {
                  return (
                    <div key={item.role}>
                      <RoleTab
                        onClick={() => {
                          setActiveRoleIndex(index)
                          // console.log(tl.scrollTrigger.refresh)
                        }}
                        active={index === activeRoleIndex}
                      >
                        {item.role}
                      </RoleTab>
                    </div>
                  )
                })}
            </StyledRolesRow>
          </Roles>
          <People>
            <StyledRow>
              <StyledSlider {...settings}>
                {roles &&
                  roles.length > activeRoleIndex &&
                  roles[activeRoleIndex].people &&
                  roles[activeRoleIndex].people.map(person => (
                    <StyledWrapper
                      key={person.name}
                      className={"box"}
                      xs={12}
                      sm={6}
                      md={4}
                      xl={4}
                    >
                      <PersonImg
                        src={person?.image?.sourceUrl}
                        alt={person?.image?.alt}
                      />
                      <PersonInfo>
                        <PersonName>{person.name}</PersonName>
                        <PersonJobTitle>{person.jobtitle}</PersonJobTitle>
                        <PersonContent>
                          <Row>
                            <Col xs={3} md={3} xl={3}>
                              <InfoType>Location:</InfoType>
                            </Col>
                            <Col xs={9} md={9} xl={9}>
                              <InfoContent>{person.location}</InfoContent>
                            </Col>
                            {person?.email && (
                              <>
                                <Col xs={3} md={3} xl={3}>
                                  <InfoType>Email:</InfoType>
                                </Col>
                                <Col xs={9} md={9} xl={9}>
                                  <InfoContent>
                                    <a
                                      href={"mailto:" + person.email}
                                      target="_blank"
                                      className="no-link"
                                      rel="noreferrer"
                                    >
                                      {person.email}
                                    </a>
                                  </InfoContent>
                                </Col>
                              </>
                            )}
                          </Row>
                        </PersonContent>
                        <PersonContent>{person.content}</PersonContent>
                      </PersonInfo>
                      {person.linkedinprofile && (
                        <a
                          href={person.linkedinprofile}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <StyledButton text={`Connect with ${person.name}`}>
                            <svg
                              role="img"
                              xmlns=""
                              width="2200mm"
                              height="2100mm"
                              viewBox="0 0 1000 1000"
                              style={{ maxWidth: "22px", height: "auto" }}
                            >
                              <path
                                id="path"
                                style={{
                                  opacity: "1",
                                  vectorEffect: "none",
                                  fill: "#ffffff",
                                  fillOpacity: "1",
                                }}
                                d=" M 336 332C 336 332 336 789 336 789C 336 789 211 789 211 789C 211 789 211 332 211 332C 211 332 336 332 336 332M 346 206C 346 206 346 206 346 206C 346 247 313 280 273 280C 233 280 201 247 201 206C 201 166 233 133 273 133C 313 133 346 166 346 206M 834 508C 834 508 834 789 834 789C 834 789 709 789 709 789C 709 789 709 556 709 556C 709 416 543 427 543 556C 543 556 543 789 543 789C 543 789 419 789 419 789C 419 789 419 332 419 332C 419 332 543 332 543 332C 543 332 543 406 543 406C 601 298 834 290 834 508"
                                transform=""
                              ></path>
                            </svg>
                          </StyledButton>
                        </a>
                      )}
                    </StyledWrapper>
                  ))}
              </StyledSlider>
            </StyledRow>
          </People>
        </MB65>
      </Wrapper>
    </StyledContainer>
  )
}

export default AboutTeam
