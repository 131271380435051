import React, { useState } from "react"
import Slider from "react-slick"
import styled, { css } from "styled-components"

import Hidden from "~components/Hidden"
import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

const Wrapper = styled.div`
  margin: 32px 0;
  margin-bottom: 32px;
  width: 100%;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin: 70px 0;
  }
`

const ValuesWrapper = styled.div`
  margin: 0px 180px;
  @media (max-width: 1280px) {
    margin: 0px 16px;
  }
`

const Title = styled.div`
  color: #6fc2b4;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
  }
`

const Subtitle = styled.div`
  margin-bottom: 16px;
  color: #003d6a;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  margin-top: 10px;

  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 25px;
    font-weight: 300;
    line-height: 30px;
    margin-bottom: 15px;
  }
`

const FullWidth = styled.div`
  width: 100%;
`

const ValueItem = styled.div`
  background-color: ${props => (props.color ? props.color : "white")};
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.31);
  color: white;
  border-top-right-radius: 22px;
  border-bottom-left-radius: 22px;
  padding: 16px;
  min-height: 200px;
  display: flex;
  align-items: center;
  transition: all ease 0.5s;
  opacity: 1;
  padding-top: 30px;
  padding-bottom: 30px;
  @media ${({ theme }) => theme.breakpoint.md} {
    padding: 0;
    opacity: 0.5;

    &.active {
      transform: scale(1.05);
      transition: all ease 0.5s;
      box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.31);
      opacity: 1;
      cursor: pointer;
    }

    &:hover {
      transform: scale(1.05);
      transition: all ease 0.5s;
      box-shadow: 0px 2px 36px rgba(0, 0, 0, 0.31);
      opacity: 1;
      cursor: pointer;
    }
  }
`

const ValueItemTitle = styled.div`
  margin-bottom: 8px;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
  }
`

const ValueItemContent = styled.div`
  text-align: center;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1;
  display: ${props => (props.active ? "block" : "none")};
  @media ${({ theme }) => theme.breakpointDown.md} {
    display: block;
  }
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: center;

  @media ${({ theme }) => theme.breakpoint.md} {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    text-align: center;
  }
`

const ColWrapper = styled(Col)`
  margin: 0 auto;
  &:nth-child(1) ${ValueItem} {
    transform-origin: center right;
  }
  &:nth-child(2) ${ValueItem} {
    transform-origin: center left;
  }
  &:nth-child(3) ${ValueItem} {
    transform-origin: center right;
  }
  &:nth-child(4) ${ValueItem} {
    transform-origin: center left;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    margin: initial;
    margin-top: 30px;
  }
`

const settings = {
  // centerMode: true,
  dots: false,
  arrows: false,
  appendArrows: false,
  infinite: true,
  slidesToShow: 1.3,
  slidesToScroll: 1,
  speed: 2000,
  autoplay: false,
  autoplaySpeed: 10000,
  draggable: true,
  accessibility: false,
  swipe: true,
  responsive: [
    {
      breakpoint: 9999,
      settings: "unslick",
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        centerPadding: "30px",

        slidesToScroll: 1,
        infinite: true,
        // dots: true,
      },
    },
  ],
}

const Section = styled.section``

const StyledSlider = styled(Slider)`
  @media ${({ theme }) => theme.breakpointDown.md} {
    .slick-list {
      overflow: visible;
    }
    .slick-slide {
      height: inherit !important;
    }
    .slick-track {
      display: flex !important;
    }
    & > div,
    & > div > div,
    & > div > div > div {
      height: 100%;
    }
    width: 100%;
  }
  @media ${({ theme }) => theme.breakpoint.md} {
    all: inherit;
  }
`

const StyledFullContainer = styled(Container)`
  @media ${({ theme }) => theme.breakpointDown.md} {
    max-width: 100% !important;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow-x: hidden !important;
    ${ValuesWrapper} {
      margin: 0;
    }
  }
`

const Value = ({ onMouseEnter, active, title, content, color, className }) => {
  return (
    <ColWrapper xs={12} sm={6} md={6} xl={6}>
      <ValueItem
        className={className}
        onMouseEnter={onMouseEnter}
        color={color}
        active={active}
      >
        <FullWidth>
          <ValueItemTitle>{title}</ValueItemTitle>
          <ValueItemContent active={active}>{content}</ValueItemContent>
        </FullWidth>
      </ValueItem>
    </ColWrapper>
  )
}

const AboutValues = ({ title, subtitle, values }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <Section id="AboutValues">
      <Wrapper>
        <Container overflowDisabled>
          <Row>
            <Col xs={12} md={6} xl={6}>
              <Title>{title}</Title>
              <Subtitle>{subtitle}</Subtitle>
            </Col>
          </Row>
        </Container>
        <StyledFullContainer overflowDisabled>
          <ValuesWrapper>
            <Row>
              <StyledSlider {...settings}>
                {values && values.length > 0 && (
                  <Value
                    onMouseEnter={() => setActiveIndex(0)}
                    active={activeIndex === 0}
                    color="#EF751A"
                    title={values[0].title}
                    content={values[0].content}
                    className={activeIndex === 0 ? "active" : null}
                  />
                )}
                {values && values.length > 1 && (
                  <Value
                    onMouseEnter={() => setActiveIndex(1)}
                    active={activeIndex === 1}
                    color="#C1D100"
                    title={values[1].title}
                    content={values[1].content}
                    className={activeIndex === 1 ? "active" : null}
                  />
                )}
                {values && values.length > 2 && (
                  <Value
                    onMouseEnter={() => setActiveIndex(2)}
                    active={activeIndex === 2}
                    color="#92D1F4"
                    title={values[2].title}
                    content={values[2].content}
                    className={activeIndex === 2 ? "active" : null}
                  />
                )}
                {values && values.length > 3 && (
                  <Value
                    onMouseEnter={() => setActiveIndex(3)}
                    active={activeIndex === 3}
                    color="#6FC2B4"
                    title={values[3].title}
                    content={values[3].content}
                    className={activeIndex === 3 ? "active" : null}
                  />
                )}
              </StyledSlider>
            </Row>
          </ValuesWrapper>
        </StyledFullContainer>
      </Wrapper>
    </Section>
  )
}

export default AboutValues
