import React from "react"
import styled from "styled-components"

import Container from "~layout/Container"
import Row from "~layout/Row"
import Col from "~layout/Col"

// const HeroWrapper = styled.div`
//   position: relative;
//   color: white;

//   margin-left: 0px;
//   margin-right: 16px;

//   @media ${({ theme }) => theme.breakpoint.lg} {
//     margin-left: calc((100vw - 1356px) / 2 - 104px);
//     margin-right: calc((100vw - 1356px) / 2);
//   }
// `
// const HeroImg = styled.img`
//   position: relative;
//   display: block;
//   max-height: 465px;
//   width: 100%;
//   text-align: center;
//   border-top-right-radius: 22px;
//   z-index: -1;
//   @media ${({ theme }) => theme.breakpoint.lg} {
//     border-bottom-left-radius: 22px;
//   }
// `

// const LinearGradient = styled.div`
//   width: 100%;
//   max-height: 465px;
//   border-top-right-radius: 22px;
//   background-image: linear-gradient(
//     to left,
//     rgb(0, 0, 0, 0),
//     rgb(0, 0, 0, 0.77)
//   );
//   @media ${({ theme }) => theme.breakpoint.lg} {
//     border-bottom-left-radius: 22px;
//   }
// `

// const HeroContentContainer = styled(Container)`
//   position: absolute;
//   right: 0;
// `

// const HeroText = styled.div`
//   position: absolute;
//   text-rendering: optimizeLegibility;
//   top: 50%;
//   transform: translate(0, -50%);
//   left: 10%;
//   max-width: 648px;
// `
// const HeroTitle = styled.div`
//   color: #ffffff;
//   font-family: Palanquin;
//   font-size: 30px;
//   font-weight: 500;
//   line-height: 33px;
// `

// const HeroSubtitle = styled.div`
//   font-weight: 300;
//   font-size: 1.375rem;
//   line-height: 1;
// `

// // const StaticHero = ({ image, title, subtitle }) => {
// //   return (
// //     <HeroWrapper>
// //       <HeroText>
// //         {title && <HeroTitle>{title}</HeroTitle>}
// //         {subtitle && <HeroSubtitle>{subtitle}</HeroSubtitle>}
// //       </HeroText>
// //       <LinearGradient>
// //         <HeroImg src={image.sourceUrl} alt={image.alt} />
// //       </LinearGradient>
// //     </HeroWrapper>
// //   )
// // }

// const StaticHero = ({ image, title, subtitle }) => {
//   return (
//     <HeroWrapper>
//       <HeroContentContainer>
//         <Row>
//           <Col xs="12" md="10" lg="6">
//             <HeroTitle>{title}</HeroTitle>
//           </Col>
//         </Row>
//       </HeroContentContainer>
//       <LinearGradient>
//
//       </LinearGradient>
//     </HeroWrapper>
//   )
// }

const Surface = styled.div`
  position: relative;

  border-top-right-radius: 22px;
  @media ${({ theme }) => theme.breakpoint.xl} {
    border-bottom-left-radius: 22px;
  }

  overflow: hidden;
  margin-left: 0px;
  margin-right: 0px;

  /* It's to keep the container width */
  transform: translateX(-16px);

  @media ${({ theme }) => theme.breakpoint.xl} {
    transform: translateX(0);
    margin-left: calc((100vw - 1344px) / 2 - 134px);
    margin-right: calc((100vw - 1344px) / 2);
  }
`

const BackgroundWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
`

const HeroImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const LinearGradient = styled.div`
  position: absolute;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  z-index: 2;

  background-image: linear-gradient(
    to left,
    rgb(0, 0, 0, 0),
    rgb(0, 0, 0, 0.77)
  );
`

const ContentContainer = styled(Container)`
  position: relative;
  right: -16px;

  padding-top: 32px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  height: 350px;

  @media ${({ theme }) => theme.breakpoint.sm} {
    position: relative;
    right: -16px;
  }

  @media ${({ theme }) => theme.breakpoint.md} {
    height: 350px;
  }

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-top: 31px;
    padding-bottom: 0;
    height: 465px;
  }

  @media ${({ theme }) => theme.breakpoint.xl} {
    padding-top: 0;
    right: 0;
    padding-left: 20.25px;
    padding-right: 6.75px;
    margin-right: 0;
    padding-bottom: 0;
  }

  z-index: 3;

  color: #fff;
  font-family: Palanquin;

  ${Row} + ${Row} {
    padding-top: 19px;
  }
`

const HeroTitle = styled.div`
  font-family: Palanquin;
  font-size: 30px;
  font-weight: 500;
  line-height: 33px;

  @media ${({ theme }) => theme.breakpoint.lg} {
    font-size: 40px;
    font-weight: 600;
    line-height: 40px;
  }
`

const TextWrapper = styled.div`
  padding-right: 16px;

  @media ${({ theme }) => theme.breakpoint.lg} {
    padding-right: 0;
  }
`

const DivWrapper = styled.div`
  width: 100%;
`

const HeroSubtitle = styled.div`
  font-size: 18px;
  font-weight: 100;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.lg} {
    font-size: 22px;
    font-weight: 300;
    line-height: 30px;
  }
`

const StaticHero = ({ image, title, subtitle }) => {
  return (
    <Surface>
      <ContentContainer>
        <DivWrapper>
          <Row>
            <Col xs="12" sm="10" md="8" xl="6">
              <TextWrapper>
                {title && <HeroTitle>{title}</HeroTitle>}
              </TextWrapper>
            </Col>
          </Row>
          <Row>
            <Col xs="10" md="6" xl="5" className="abcd">
              <TextWrapper>
                {subtitle && <HeroSubtitle>{subtitle}</HeroSubtitle>}
              </TextWrapper>
            </Col>
          </Row>
        </DivWrapper>
      </ContentContainer>
      <BackgroundWrapper>
        <LinearGradient />
        <HeroImg src={image.sourceUrl} alt={image.alt} />
      </BackgroundWrapper>
    </Surface>
  )
}

export default StaticHero
