import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./slickstyles.css"

import { gsap, Power1 } from "@gsap/shockingly/dist/gsap"
import { ScrollToPlugin } from "@gsap/shockingly/ScrollToPlugin"
import React from "react"
import Slider from "react-slick"
import styled from "styled-components"

import Hidden from "~components/Hidden"
import Col from "~layout/Col"
import Container from "~layout/Container"
import Row from "~layout/Row"

import ButtonLink from "../../components/ButtonLink"

const Wrapper = styled.div`
  width: 100%;
`

const ContentWrapper = styled.div`
  margin: 32px 0px;
  @media ${({ theme }) => theme.breakpoint.md} {
    margin: 70px 0px;
  }
`

const Title = styled.div`
  color: #003d6a;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 15px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 25px;
    font-weight: 600;
    line-height: 30px;
  }
`

const Content = styled.div`
  color: #003d6a;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }
`

const FeaturedImg = styled.img`
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.26);
  position: relative;
  display: block;
  width: 100%;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 25px;
  margin-bottom: 0;
`

const FeaturedTitle = styled.div`
  span {
    font-weight: 400;
  }
  color: #003d6a;
  font-size: 12px;
  font-weight: 300;
  line-height: 20px;
  margin-top: 8px;
  @media ${({ theme }) => theme.breakpoint.md} {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
    margin-top: 13px;
  }
`
const Slide = styled.div`
  ${FeaturedImg} {
    box-shadow: none;
  }
`
const settings = {
  // centerMode: true,
  dots: false,
  arrows: false,
  appendArrows: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 2000,
  autoplay: true,
  autoplaySpeed: 5000,
  draggable: true,
  accessibility: false,
  swipe: true,
  centerMode: true,
  centerPadding: "20px",
}
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${({ theme }) => theme.breakpoint.md} {
    @media ${({ theme }) => theme.breakpointDown.xl} {
      a {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.18px;
      }
    }
  }
  @media ${({ theme }) => theme.breakpointDown.lg} {
    flex-wrap: wrap;
    a {
      margin-top: 10px;
      width: calc(50% - 5px);
      &:nth-child(even) {
        margin-left: 5px;
      }
    }
  }
`

const StyledSlider = styled(Slider)`
  @media ${({ theme }) => theme.breakpointDown.md} {
    .slick-list {
      box-shadow: none;
      border-radius: initial;
    }
    .slick-slide {
      padding-left: 7px;
      padding-right: 7px;
      padding-top: 20px;
    }
    ${FeaturedImg} {
      box-shadow: 0px 2px 20px rgb(0 0 0 / 26%);
    }
    margin-bottom: 50px;
  }
`

const ScrollTo = (selector, offset = null) => {
  if (typeof window !== "undefined") {
    gsap.to(window, {
      scrollTo: {
        y: document?.querySelector(selector)?.offsetTop + offset,
      },
      duration: 2,
      ease: Power1.easeInOut,
    })
  }
}

const AboutPortfolio = ({ title, content, featured }) => {
  return (
    <div>
      <Wrapper>
        <Container overflowDisabled>
          <ContentWrapper>
            <Row>
              <Col xs={12} md={6} xl={6}>
                <Title>{title}</Title>
              </Col>
              <Col xs={12} md={6} xl={6}>
                <Content dangerouslySetInnerHTML={{ __html: content }} />
                <ButtonWrapper>
                  <Hidden mdUp>
                    <ButtonLink
                      to="#team"
                      title="Our Team"
                      color="#76C5B7"
                      borderColor="#76C5B7"
                      initialText="Our Team"
                      changedText="Click Here"
                      solid
                      onClick={e => {
                        e.preventDefault()
                        ScrollTo("#team", -120)
                      }}
                    />
                  </Hidden>
                  <Hidden smDown>
                    <ButtonLink
                      solid
                      to="#team"
                      title="Our Team"
                      color="#76C5B7"
                      borderColor="#76C5B7"
                      initialText="Our Team"
                      changedText="Click Here"
                      solid
                      onClick={e => {
                        e.preventDefault()
                        ScrollTo(".timeline", 3810)
                      }}
                    />
                  </Hidden>
                  <ButtonLink
                    solid
                    to="/portfolio"
                    title="Our Portfolio"
                    color="#eb7c00"
                    borderColor="#eb7c00"
                    solid
                    initialText="Our Portfolio"
                    changedText="Click Here"
                  />
                  <ButtonLink
                    solid
                    to="#AboutValues"
                    title="Our Values"
                    color="#C1D100"
                    borderColor="#C1D100"
                    initialText="Our Values"
                    solid
                    changedText="Click Here"
                    onClick={e => {
                      e.preventDefault()
                      ScrollTo("#AboutValues", -150)
                    }}
                  />
                  <ButtonLink
                    solid
                    to="#timelineWrapper"
                    title="Our History"
                    color="#92D1F4"
                    borderColor="#92D1F4"
                    solid
                    initialText="Our History"
                    changedText="Click Here"
                    onClick={e => {
                      e.preventDefault()
                      ScrollTo("#timelineWrapper", -150)
                    }}
                  />
                </ButtonWrapper>
              </Col>
            </Row>
          </ContentWrapper>
          <Hidden smDown>
            <Row>
              {featured &&
                featured.map(item => (
                  <Col key={item.title} xs={12} md={4} xl={4}>
                    <FeaturedImg
                      src={item.image.sourceUrl}
                      alt={item.image.alt}
                    />
                    <FeaturedTitle>
                      <span>Asset: </span>
                      {item.title}
                    </FeaturedTitle>
                  </Col>
                ))}
            </Row>
          </Hidden>
        </Container>
        <Hidden mdUp>
          <StyledSlider {...settings} className="AboutPortfolio">
            {featured &&
              featured.map(item => (
                <Slide key={item.title}>
                  <FeaturedImg
                    src={item.image.sourceUrl}
                    alt={item.image.alt}
                  />
                  <FeaturedTitle>
                    <span>Asset: </span>
                    {item.title}
                  </FeaturedTitle>
                </Slide>
              ))}
          </StyledSlider>
        </Hidden>
      </Wrapper>
    </div>
  )
}

export default AboutPortfolio
